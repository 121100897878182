.loginPage {
  position:absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
}

.loginContainer {
  /* background-color: #3951b2; */
  /* position: absolute; */
  /* min-width: 600px; */
  /* min-height: 700px; */
  /* display: flex; */
  /* justify-content: center; */
  margin-top: 30px;
  display: flex;

  align-items: center;
  left: 20vw;
  top: 30vh;
}

.content {
  display: inline-block;
  text-align: center;
  margin-top: 20vh;
  margin-left: 25vh;
}

img {
  height: auto;
  margin: auto;
}

.loginForm {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  width: 20vw;
}

.buttonRow {
  display: flex;
  justify-content: space-between;
}

#loginButton {
  margin-top: 10px;
  background-color: #124251;
  color: #f4f7fe;
  padding: 10px 30px;
}

.buttonRecovery {
  font-size: 14px;
  margin: 8px 0 0 0;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
}

.utilLogo {
  width: 10vw;
}

.error-login {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: red;
}

.label-float {
  position: relative;
  padding-top: 15px;
  width: 100%;
}

.label-float input {
  border-radius: 10px;
  outline: none;
  width: 100%;
  min-width: 250px;
  padding: 15px 20px;

  font-size: 25px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
}

.label-float input:focus {
  border: 2px solid #3951b2;
}

.label-float input::placeholder {
  color: transparent;
}

.label-float label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: white;
  padding: 5px;
  box-sizing: border-box;
}

.label-float input:required:invalid + label {
  color: red;
}
.label-float input:focus:required:invalid {
  border: 2px solid red;
}
.label-float input:required:invalid + label:before {
  content: "*";
}
.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 0;
  color: #3951b2;
}

.loginFormContainer a {
  text-decoration: underline;
  position: absolute;
  left: 5px;
  margin-top: 5px;
}

.MuiFormLabel-root{
  /* background-color: blue; */
  
}

.TextField-without-border-radius fieldset {
  border-radius: 100px;
}

/* input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px none inset;
  box-shadow: 0 0 0 1000px none inset;
} */


@media screen and (max-width: 800px) {

  .content {
    display: flex-column;
    justify-content: center;
    align-items: center;
    margin-left: 10%;
    margin-right: auto;
  }

  .utilLogo {
    height: 150px;
    
  }
  
  .loginForm {
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  .buttonRow {
    display: flex;
    justify-content: space-between;
  }
  
  
  
  .buttonRecovery {
    font-size: 14px;
    margin: 8px 0 0 0;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .utilLogo {
    width: 200px;
  }
  
  .error-login {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: red;
  }
  
  .label-float {
    position: relative;
    padding-top: 15px;
    width: 100%;
  }
  
  .label-float input {
    border-radius: 10px;
    outline: none;
    width: 100%;
    min-width: 250px;
    padding: 15px 20px;
  
    font-size: 25px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -webkit-appearance: none;
  }
  
  .label-float input:focus {
    border: 2px solid #3951b2;
  }
  
  .label-float input::placeholder {
    color: transparent;
  }
  
  .label-float label {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 8px);
    left: 15px;
    transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    background-color: white;
    padding: 5px;
    box-sizing: border-box;
  }
  
  .label-float input:required:invalid + label {
    color: red;
  }
  .label-float input:focus:required:invalid {
    border: 2px solid red;
  }
  .label-float input:required:invalid + label:before {
    content: "*";
  }
  .label-float input:focus + label,
  .label-float input:not(:placeholder-shown) + label {
    font-size: 13px;
    top: 0;
    color: #3951b2;
  }
  
  .loginFormContainer a {
    text-decoration: underline;
    position: absolute;
    left: 5px;
    margin-top: 5px;
  }
  
  .MuiFormLabel-root{
    /* background-color: blue; */
    
  }
  
  .TextField-without-border-radius fieldset {
    border-radius: 100px;
  }

}
