.SensorDetailsCard{
    border: solid 1px black;
    padding: 5px;
    border-radius: 5px;
}
/* 
.deleteButton {
  font-family: roboto;
  color: white;
  background-color: rgb(174, 0, 0);
  border-radius: 5x;
  padding: 5px 10px;
} */
