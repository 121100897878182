img {
    margin: auto;
    width: 75%;
  }
  
  .image {
    height: 75%;
    display: flex;
  }
  
  .cards {
    border-top: 1px solid #d9d9d9;
    height: 25%;
    text-align: center;
  }
  
  .GroupCard {
    width: 273px;
    height: 237px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    margin: 10px;
  }
  