.mainCompanyAnalytics {
  display: flex;
  height: 92vh;
  width: 97vw;
}

.side {
  margin: 10px;
  border: 0.1vh solid #16161627;
  width: 25vw;
  height: 92vh;
}

.graphs {
  background-color: blueviolet;
}

.dateRangeWrapper {
  border: 0.1vh solid #16161627;
  height: 5.5vh;
  border-radius: 0.25rem;
  position: relative;
}

.dateRangeWrapper:hover {
  border-color: black !important;
}

.dateRangeWrapper label {
  pointer-events: none;
  position: absolute;
  left: 15px;
  margin-top: -10px;
  background-color: white;
  padding: 0px 5px 0px 5px;
  box-sizing: border-box;
  font-size: 13px;
  color: #3951b2;
}

.DateRangePicker_picker {
  z-index: 2 !important;
}

.DateRangePicker {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.DateInput {
  width: 8vw !important;
}

.DateInput_input {
  padding-bottom: 0px !important;
  font-size: 1em !important;
}

.graphsPanel {
  width: 73%;
  height: 92vh;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.graphsPanel > div > div {
  background-color: rgb(255, 255, 255);
  margin: 10px;
  border: 0.1vh solid #16161627;
}

h2 {
  font-family: roboto;
  font-size: 18px;
  font-weight: 500;
  margin: 15px 0px 0px 15px;
}

.test {
  text-decoration: underline;
  font-size: roboto;
  font-weight: 400;
  text-align: right;
  margin: 0;
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  padding: 0;
}

.form-div-range {
  border: 0.1vh solid #16161627 !important;
  margin: 0 !important;
}

.form-div-range:hover {
  border-color: black !important;
}

.form-div-range input {
  padding: 0px !important;
  width: auto !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

input[type="time"] {
  position: relative;
}

select::-webkit-keygen-select {
  color: red;
}

.c {
  background-color: blue;
  display: grid;
  flex-direction: column;
}

.c > h2 {
  height: fit-content;
}

.timeArrow {
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}

.t3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: initial;
}

.t1 {
  font-weight: 400;
  font-size: 110px;
  text-align: center;
  margin: 0;
}

.t2 {
  text-align: center;
}

.submmitButton {
  margin: 0 !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  height: 5.5vh;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 5.5vh;
  display: flex;
  align-items: center;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 9px) scale(1);
}

form {
  padding: 0% 5% !important;
}

.containerb {
 
width: 100vw;
height: 100vh;
display: flex;
justify-content: flex-start;
align-items: flex-start;

}

.dark {
  background-color: #161616;

}