.configMain {
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-template-rows: 78px 1fr;
  grid-template-areas: "sidenav topbar" "sidenav content";
  height: 100vh;
  width: 100vw;
  background-color: #f4f7fe;
}

.searchDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.1vh solid #16161627;
  padding: 5px;
  /* border-radius: 5px; */
  margin: 0 10px;
}

.searchDiv > input {
  border: none;
}

.searchDiv > input:focus {
  border: none;
  outline: none;
}

.mainViewGroup {
  display: grid;
  /* height: 100%; */
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 10px;
  margin: 10px;
  height: 92vh;
  width:97vw;
}

.mainViewGroup > div {
  padding: 10px;
  background-color: white;
  /* border: 1px solid #ebebeb; */
  /* border: 1px solid #16161627; */
  /* border-radius: 6px; */
  grid-template-rows: 9fr 1fr;
  display: grid;
  

}

.collumn {
  /* display: grid; */
  /* grid-template-rows: 9fr 1fr; */
  
}

.dtsDiv {
}

.list {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.bottomButton {
  font-family: roboto;
  color: white;
  background-color: #003b72;
  /* border-radius: 0.5rem; */
  padding: 5px 10px;
  border: none;
  display: inline-block;
  margin-right: 60px;
}

.sectionTittle{
  font-family: roboto;
  font-weight: 400;
  font-size: 20px;
  margin-left: 10px;
}

.groupDiv{
  border: 0.1vh solid #16161627;
  border-radius: 0.5rem;
  margin: 10px;
  padding: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.groupDiv > p {
  margin-left: 10px;
}

.groupDiv > img {
  height: 50px;
  width: auto;
  margin: 0;
}

.groupDiv > .editIcon {
  margin-left: auto;
  margin-right: 20px;
}

.testeScroll{
  margin: 10px;
  border: 0.1vh solid #16161627;
  border-radius: 0.5rem;
  overflow-y: scroll;
  height:59vh;
}