.DeleteConfirmationDialog {
  background-color: #00000060;
  height: 100vh;
  width: 100vw;
  position: absolute /*relative and fixed elements also works*/;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: roboto;
}

.dialogBox {
  display: grid;
  height: 30vh;
  width: 40vw;
  background-color: white;
  margin: auto;
  border-radius: 10px;
}

.buttons{
    display: flex;
    justify-content: space-around;
}
