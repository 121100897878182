.double {
  display: flex;
}

.buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}

.buttons > button {
  color: white;
  border-radius: 5x;
  padding: 5px 10px;
  border: none;
}

.delete {
  background-color: rgb(130, 0, 0);
}
