
#text{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(55, 79, 126); */

}
#container{
  display: flex;
  flex-direction: row;
  
}

.mensagem{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width:100%;
  gap:5px
}

@media screen and (max-width: 800px){

  #container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  #text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* backgr
    ound-color: red; */
  }
  
}