.configMain {
    display: grid;
    /* grid-template-columns: 70px 1fr; */
    /* grid-template-rows: 78px 1fr; */
    /* grid-template-areas: "sidenav topbar" "sidenav content"; */
    height: 100vh;
    width: 100vw;
    background-color: #f4f7fe;
  }

  .form{
    /* background-color: red; */
    padding-right: 70%;
  }
  
  .mainViewGroups {
    /* background-color: white; */
    height: 100%;
    display: grid;
    margin: 1vh;
    width:97vw;
  }
  
  .jumbo {
    
    background-color: #fff;
    /* border-radius: 0.5rem; */
    padding: 1vh;
    display: grid;
    grid-template-rows: 10% 90%;
    /* border: 1px solid #16161627; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.05); */

  }
  
  .playground {
    
    display: flex;
    /* height: auto; */
  }
  
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .buttons {
    display: flex;
    align-items: center;
  }
  
  .newOrgButton {
    margin-right: 1vh ;
    /* margin-top: 17px; */
    height: 36px;
    background-color: #003b72;
    color: #fff;
    font-size: 1.5vh;
    border-radius: 0.5rem;
    font-weight: 500;
    
    
    border: 0;
    padding: 1vh;
  }
  
  .search {
    display: flex;
    align-items: center;
    padding: 1vh;
    border-radius: 0.5rem;
    border: solid 0.1vh black;
    /* margin-left: 3vw; */
  }

  .search > input{
    height: auto;
    border: none;
  }
  .search > input{
    height: auto;
    width: 20vw;
    border: none;
  }

  .search > input:focus {
    border: none;
    outline: none;
  }


  a{
    height: fit-content;
  }

  .empty{
    background-color: gray;
    height: 20vh;
    width: 25vw;
    margin: 1vh;
    border-radius: 0.5rem;
  }