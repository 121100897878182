

.mainViewDt {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  gap: 1vh;
  height: 92vh;
  /* background-color: #f4f7fe; */
  width:97vw;
  margin:1vh;


}

  .mainViewDt > div{
    /* background-color: white; */
    /* margin: 10px 5px; */
    /* border-radius: 0.5rem; */
    /* border: 1px solid #16161627; */

  }
  .GraphsContainer{
    display: grid;
    /* height: 100%; */
    grid-template-columns: 1fr 1fr;
  }