.CompanyCard {
    margin: 10px;
    border: 0.1vh solid #16161627;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 3px 10px;
    cursor: pointer;
  }
  
  .icon{
      align-self: flex-end;
      margin: 0 20px 20px 0;
  }
  
  .selected{
    border: solid 5px rgb(44, 0, 116);
  }

  .icon:hover {
    transform: scale(1.2);
    cursor: pointer;
  }