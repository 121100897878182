#factoryfilter {
    font-family: 'Roboto', Courier, monospace;
  font-weight: 400;
  font-size: 15px;
}
  
#sectorfilter {
    font-family: 'Roboto', Courier, monospace;
    font-weight: 400;
    font-size: 15px;
}

button {
    border-radius: 0.5rem;
    font-family: 'Roboto', Courier, monospace;
    font-weight: 400;
    font-size: larger;
    border: none;
    background-color: #003B72;
    color: antiquewhite;
    
}

/* button:hover {
    border-radius: 0.5rem;
    font-family: 'Roboto', Courier, monospace;
    font-weight: 600;
    font-size: larger;
    border: none;
    background-color: #01203d;
    color: antiquewhite;
    
} */

button:active {
    border-radius: 0.5rem;
    font-family: 'Roboto', Courier, monospace;
    font-weight: 400;
    font-size: larger;
    border: none;
    background-color: #ffffff;

    color: rgb(0, 0, 0);
    
}