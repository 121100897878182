/* DateRangePickerStyles.css */

/* Tema claro */
.light-theme .DateRangePicker,
.light-theme .DateRangePicker_picker,
.light-theme .DateRangePickerInput,
.light-theme .DayPicker,
.light-theme .CalendarMonth,
.light-theme .CalendarDay {
  background-color: #ffffff;
  color: #000000;
}

/* Tema escuro */
.dark-theme .DateRangePicker,
.dark-theme .DateRangePicker_picker,
.dark-theme .DateRangePickerInput,
.dark-theme .DayPicker,
.dark-theme .CalendarMonth,
.dark-theme .CalendarDay {
  background-color: #3c3c42;
  color: #ffffff;
}

.dark-theme .CalendarDay__default {
  border: 1px solid #444;
}

.dark-theme .CalendarDay__selected,
.dark-theme .CalendarDay__selected:active,
.dark-theme .CalendarDay__selected:hover {
  background: #1a1a1a;
  border: 1px double #fff;
  color: #fff;
}

.dark-theme .CalendarDay__hovered_span,
.dark-theme .CalendarDay__hovered_span:hover {
  background: #444;
  border: 1px double #ddd;
  color: #fff;
}

.dark-theme .CalendarDay__blocked_out_of_range {
  background: #2b2b2b;
  border: 1px solid #444;
  color: #666;
}

.dark-theme .CalendarDay__blocked_out_of_range:active,
.dark-theme .CalendarDay__blocked_out_of_range:hover {
  background: #2b2b2b;
  border: 1px solid #444;
  color: #666;
}

.textfield {
  background-color: transparent;
  color: white;
}