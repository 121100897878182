form {
  padding: 0px 10px;
}

.tickBoxes > input {
  margin-left: 36px;
  margin-right: 8px;
  width: 20px;
  height: 20px;
}
.tickBoxes {
  margin-left: 20px;
  display: grid;
  grid-auto-rows: auto;
  grid-auto-columns: auto;
}

.tickBoxGroup {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  font-family: roboto;
}

.submmitButton {
  font-family: roboto;
  color: white;
  background-color: #003b72;
  border-radius: 5x;
  padding: 5px 10px;
  border: none;
  display: inline-block;
  margin-top: 15px;
  margin-right: 60px;
  margin-bottom: 10px;
  float: right;
}

/* ---------------------------- */
.form-div-input {
  display: flex;
  position: relative;
  padding-top: 13px;
  width: 100%;
}

.form-div-input input {
  width: 100%;
  /* min-width: 250px; */
  border: 0.1vh solid #16161627;
  
  border-radius: 0.5rem;
  outline: none;
  padding: 12px 20px 8px 20px;
  font-size: 16px;
  /* transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none; */
}

.form-div-input input:focus {
  border: 2px solid #3951b2;
}

.form-div-input input::placeholder {
  color: gray;
  /* font-size: 12px; */
}

.form-div-input label {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: white;
  padding: 5px 5px 0px 5px;
  box-sizing: border-box;

  font-size: 13px;
  top: 0;
  color: #3951b2;
}

.form-div-input input:required:invalid + label {
  color: red;
}
.form-div-input input:focus:required:invalid {
  border: 2px solid red;
}
.form-div-input input:required:invalid + label:before {
  content: "*";
}
/* .form-div-input input:focus + label,
.form-div-input input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 0;
  color: #3951b2;
} */

/* ----------------- */

.form-div-select {
  display: flex;
  position: relative;
  padding-top: 13px;
  width: 100%;
}

.form-div-select select {
  width: 100%;
  /* min-width: 250px; */
  border-radius: 5px;
  border-color: #16161627;
  outline: none;
  padding: 12px 20px 8px 20px;
  font-size: 16px;

  border-width: 1px;
  /* border-style: inset; */


  /* transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;

  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133)); */
}

select:invalid{
  color: gray;
}

option{
  color: black;
}

.form-div-select select:focus {
  border: 2px inset #3951b2;
}

/* .form-div-select select::placeholder {
  color: transparent;
} */

.form-div-select label {
  pointer-events: none;
  position: absolute;
  top: calc(50% - 8px);
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: white;
  padding: 5px 5px 0px 5px;
  box-sizing: border-box;

  font-size: 13px;
  top: 0;
  color: #3951b2;
}

/* .form-div-select select:required:invalid + label {
  color: red;
} */
/* .form-div-select select:focus:required:invalid {
  border: 2px solid red;
} */
/* .form-div-select select:required:invalid + label:before {
  content: "*";
} */
/* ----------------------- */

.form-div-range {
  display: flex;
  position: relative;
  /* padding-top: 13px; */
  width: 100%;
  border: 1px solid #000;
  border-radius: 5px;
  margin: 15px 0px;
}

.form-div-range input {
  border: none;
  width: 100%;
  /* min-width: 250px; */
  border-radius: 5px;
  outline: none;
  padding: 12px 20px 8px 20px;
  font-size: 16px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -webkit-appearance: none;
}

.form-div-range label {
  pointer-events: none;
  position: absolute;
  top: 0;
  margin-top: -15px;
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: white;
  padding: 5px 5px 0px 5px;
  box-sizing: border-box;
}

.form-div-range input:focus + label,
.form-div-range input:not(:placeholder-shown) + label {
  font-size: 13px;
  top: 0;
  color: #3951b2;
}

/* ------------------------- */

.test {
  position: relative;
  padding-top: 10px;
  width: 100%;

}

.test .css-1s2u09g-control {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  outline: none;
  font-size: 16px;

  border-width: 1px;
  border-style: inset;
  border-color: black;
}

.test .css-1s2u09g-control:focus {
  border: 2px inset #3951b2;
}

.test .css-1pahdxg-control {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  border: none;
}

.test .css-1pahdxg-control:focus {
  border: none;

  background-color: rgb(249, 20, 20);
}

.test .css-1pahdxg-control:hover {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  border: none;
}

.test label {
  pointer-events: none;
  position: absolute;
  top: -12px;
  bottom: 10px;
  left: 15px;
  transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  background-color: white;
  padding: 0px 5px 0px 5px;
  box-sizing: border-box;
  height: fit-content;
  font-size: 12px;
  color: #3951b2;
}


/* .sensorsList{
  background-color: red;
} */

.sensorsList .togle{
  color: gray;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
}

.cancel{
  color: red;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
}

button{
  cursor: pointer;
}
.axis{
  margin: 0 30px;
}

