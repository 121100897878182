.greenBadge{
  background-color: #118751;
  color: rgba(255, 255, 255, 1);
}
.yellowBadge{
  background-color: #fff0b4;
  color:#4d4d4d;
  stroke: #4d4d4d;
}
.redBadge{
  background-color: #c04040;
  color: rgba(255, 255, 255, 1);
  stroke: #FFFFFF;
  fill: #FFFFFF;
}
.badge{
  width: 73px;
  height: 32px;
  object-fit: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  font-size: 20px;
  font-family: 'Roboto', Courier, monospace;
  font-weight: 400;
  
  
}

.badge:hover{
  cursor: pointer
}
