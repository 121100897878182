.parent{
    border: 1px solid rgba(0, 0, 0, 0.87);
    border-radius: 6px;
    /* margin: 0 10px 10px 10px; */

    display: flex;
    justify-content: space-between;
    padding: 3px 10px;
    cursor: pointer;
}

ul{
    margin: 0;
}

.childDiv{
    border: 1px solid rgba(0, 0, 0, 0.87);
    /* margin: 0 10px 10px 10px; */
    align-items: center;
    margin-top: 10px;
    border-radius: 6px;
    padding: 3px 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

}

.icon{
    align-self: flex-end;
    margin: 0 20px 20px 0;
}

.digitalTwinCard{
    margin: 10px 10px 10px 10px;
}