.recommendationCardGreenBackground{
  background: rgba(131, 171, 81, 0.38);
}
.recommendationCardYellowBackground{
  background: rgba(255, 203, 5, 0.38);
}
.recommendationCardRedBackground{
  background: rgba(192, 64, 64, 0.38);
}
.recommendationCardBlueBackground{
  background: rgba(81, 171, 166, 0.38);
}

.largeIcon{
  transform: scale(2);
}
.smallIcon{
  transform: scale(1.5);
}
