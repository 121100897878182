.SensorCard {
  margin-bottom: 10px;
  border: 0.1vh solid #16161627;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding: 3px 10px;
  font-family: roboto;
}

.icon {
  align-self: flex-end;
  margin: 0 20px 20px 0;
}

.line {
  /* overflow-x: scroll; */
}

.line > p {
  display: inline-block;
  margin: 5px;
}

.variableType {
  display: inline-block;
  color: white;
  margin-left: 5px;
  border-radius: 10px;
  padding: 1px 5px;
}

.unit {
  font-size: inherit;
  margin-left: 5px;
}
