.FeedbackModal {
  z-index: 2147480000;
  background-color: #00000060;
  backdrop-filter: blur(2px);
  height: 100vh;
  width: 100vw;
  position: absolute /*relative and fixed elements also works*/;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: roboto;
}

textarea {
  width: 100%;
  margin: 0 0 0px 0;
  resize: none;
}

.mainModal {
  display: grid;
  height: fit-content;
  width: fit-content;
  background-color: white;
  margin: auto;
  border-radius: 4px;
}

.body {
  padding: 10px;
}

.head {
  color: #fff;
  background-color: #1a8f9d;
  /* height: 8vh; */
  border-radius: 4px 4px 0 0;
  /* font-weight: 500; */
  /* text-align: center; */
  /* padding: 2vh; */
}

  
.buttonsModal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  height: auto;
}

#cancelButton {
  margin-top: 10px;
    color: #124251;
    background-color: #f4f7fe;
    padding: 10px 30px;
}
.body > p {
  /* margin: 4vh 0; */
}
#sendButton {
    margin-top: 10px;
    background-color: #124251;
    color: #f4f7fe;
    padding: 10px 30px;
}

input::file-selector-button {
  font-weight: 500;
  font-family: roboto;
  background-color: #124251;
  color: white;
  width: 12vh;
  border-radius: 5px;
  border: none;
  height: 3vh;
}

@media screen and (max-width:800px) {
  
  
  textarea {
    width: 100%;
    margin: 0 0 0 0;
    resize: none;
  }
  
  .mainModal {
    display: grid;
    /* height: 30vh; */
    width: 350px;
    background-color: white;
    margin: auto;
    border-radius: 4px;
  }
  
  .body {
    padding: 20px;
  }
  
  .head {
    color: #fff;
    background-color: #1a8f9d;
    /* height: 8vh; */
    border-radius: 4px 4px 0 0;
    font-weight: 400;
    text-align: center;
    font-family: 'Roboto', Courier, monospace;
  font-weight: 400;
  font-size:larger;
    padding: 10px;
  }

  

  .body > p {
    margin: 10px 0;
    
  }
  #sendButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: #fff;
    font-family: roboto;
    background-color: #124251;
    width: 100%;
  }
  
  input::file-selector-button {
    font-weight: 500;
    font-family: roboto;
    background-color: #124251;
    color: white;
    width: 12vh;
    border-radius: 5px;
    border: none;
    height: 3vh;
  }
}