.userCard {
    margin: 10px 10px 0 10px;
    border: 0.1vh solid #16161627;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 3px 10px;
  }
  
  .icon{
      align-self: flex-end;
      margin: 0 20px 20px 0;
  }
  